.content {
  composes: subgrid from '~g';

  --subgrid-start: var(--text-content-start, start);
  --subgrid-width: var(--text-content-width, var(--grid-number-of-columns));
}

.accents,
.titles,
.bodies,
.list,
.listItem,
.types {
  &:first-child {
    margin-top: 0;
  }

  .logo + & {
    margin-top: 0;
  }
}

.alignCenter {
  .accents,
  .titles,
  .bodies {
    @media (--md) {
      text-align: center;
    }
  }

  .types {
    @media (--md) {
      margin-right: auto;
      margin-left: auto;
    }
  }
}

.accents {
  color: var(--color-accent, var(--color-accent-default));
}
