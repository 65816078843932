.footer {
  --footer-progress: 0;
  position: relative;
  z-index: 1;
  padding-top: var(--spacer-48);
  padding-bottom: var(--spacer-24);
  margin-top: calc(var(--section-border-radius) * -1);
  color: var(--color-grey-100);

  @media (--md) {
    padding-top: var(--spacer-96);
    padding-bottom: var(--spacer-48);
    transform: translate3d(0, var(--footer-progress), 0);
  }

  .footerButtonContainer {
    --icon-size: 2rem;
  }
}

.rowPrimary {
  composes: grid from '~g';
  padding-bottom: var(--spacer-64);

  @media (--md) {
    padding-bottom: var(--spacer-124);
  }

  @media (--lg) {
    padding-bottom: 14.4rem;
  }
}

.rowSecondary {
  &::before {
    display: block;
    height: 2px;
    border-radius: 2px;
    margin-bottom: var(--spacer-24);
    background: var(--color-alpha-light-10);
    content: '';
    grid-column: start / end;
  }

  @media (--md) {
    composes: grid from '~g';
    flex-flow: row nowrap;

    &::before {
      margin-bottom: var(--spacer-48);
    }
  }
}

.columnLogo {
  grid-column: start / end;

  @media (--md) {
    grid-column: start / span 3;
  }

  svg {
    width: 100%;
  }
}

.primaryLogo {
  --graphic-scale: 1;
}

.columnPrimaryLinks {
  composes: subgrid from '~g';
  margin-top: var(--spacer-48);
  gap: var(--spacer-48);

  @media (--md) {
    gap: var(--spacer-48) 0;
    grid-column: start / end;
    grid-template-columns: repeat(3, 1fr);
  }

  @media (--lg) {
    margin-top: 0;
    grid-column: col 4 / span 9;
    row-gap: var(--spacer-40);
  }

  @media (hover: hover) {
    &:has(.linkItem:hover) {
      .linkItem {
        color: var(--color-alpha-light-50);
      }
    }
  }
}

.linkItem {
  @media (hover: hover) {
    &:hover {
      /* Need to make sure we override the above */
      /* stylelint-disable-next-line declaration-no-important */
      color: var(--color-alpha-grey-100) !important;
    }
  }
}

.columnPrimaryLink {
  grid-column: start / end;

  @media (--md) {
    grid-column: unset;

    /* set the first 2 columns to span 3 rows, last column will be autoflow to contain 3 groups in one column */
    &:nth-child(1),
    &:nth-child(2) {
      grid-row: 1/4;
    }
  }
}

.columnBottomLeftCopyright {
  z-index: var(--z-index-0);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  gap: var(--spacer-24);
  grid-column: start / end;

  @media (--md) {
    z-index: initial;
    gap: var(--spacer-20);
    grid-column: start / span 9;
  }

  @media (--lg) {
    flex-wrap: nowrap;
    grid-column: start / span 10;
  }
}

.columnBottomLeftLinks {
  z-index: var(--z-index-0);
  display: flex;
  width: auto;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--spacer-16);

  @media (--md) {
    gap: var(--spacer-20);
  }
}

.columnBottomRightSocials {
  display: flex;
  flex-flow: row nowrap;
  margin: var(--spacer-24) 0 0;
  gap: var(--spacer-10);
  grid-column: start / end;

  @media (--md) {
    margin-top: 0;
    grid-column: col 10 / end;
    justify-self: end;
  }

  @media (--lg) {
    grid-column: col 11 / end;
  }
}

.socialIcon {
  position: relative;
  display: block;

  * {
    fill: var(--color-grey-100);
  }
}

.columnBottomLogo {
  composes: meta from '~typo';
  z-index: var(--z-index-1);
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  grid-column: start / end;

  @media (--md) {
    grid-column: start / span 3;
  }

  @media (--lg) {
    grid-column: start / span 2;
  }

  svg {
    width: 7rem;

    * {
      fill: var(--color-grey-60);
    }
  }
}

.bottomLogoSubtext {
  display: block;
}

.bottomLogoLink {
  color: var(--color-grey-60);
  text-decoration: none;
}

.bottomLeftCopyright {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  gap: var(--spacer-12);

  @media (--md) {
    width: auto;
  }

  .bottomLeftItem {
    width: auto;
  }
}

.bottomLeftItem {
  composes: footnote from '~typo';
  display: flex;
  width: 100%;

  @media (--md) {
    display: inherit;
    width: auto;
  }
}

.bottomLeftLink {
  color: var(--color-grey-100);
  text-decoration: none;
}

.footnotesWrapper {
  composes: grid from '~g';
  margin-top: var(--spacer-40);
  @media (--md) {
    margin-top: var(--spacer-48);
  }
}

.footnotes {
  composes: subgrid from '~g';
  composes: footnote from '~typo';
  list-style: decimal;
}

.footnote {
  grid-column: start / end;

  &:not(:last-child) {
    margin-bottom: var(--spacer-8);
  }
}

.footnoteContent {
  display: inline;
  vertical-align: top;
}

.footnoteContentBodies:last-child {
  display: inline;
}

.footnoteBackLink {
  margin-left: 0.5em;
  text-decoration: underline;
}

.backdrop {
  position: absolute;
  z-index: var(--z-index-neg);
  top: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: saturate(150%) blur(100px);
  background: var(--color-alpha-dark-80);
  mask-image: var(--top-mask-md);
}
