.table {
  position: relative;
}

.tableInner {
  composes: body from '~typo';
  overflow: auto;
  color: var(--color-alpha-light-50);

  @media (--md) {
    padding: 0;
    grid-column: 3 / span 12;
  }

  table {
    border: 1px solid var(--color-grey-30);
  }

  thead {
    background-color: var(--color-alpha-light-10);
  }

  th {
    color: var(--color-grey-88);
    font-weight: 700;
    text-align: left;
  }

  th,
  td {
    padding: var(--spacer-16) var(--spacer-24);
    border-right: 1px solid var(--color-grey-30);
  }
}
