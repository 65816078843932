$mdVideoWidth: max(18vw, 31.8rem);
$mdMargin: 2.4rem;

.floatingVideoPreviewContainerDesktop {
  position: absolute;
  top: 0;
  width: 100%;
  height: auto;
  pointer-events: none;
}

.desktop {
  display: none;

  @media (--md) {
    display: block;
  }
}

.mobile {
  display: block;

  @media (--md) {
    display: none;
  }
}

.floatingVideoPreviewWrapper {
  margin-top: var(--spacer-64);

  --button-hover-color: var(--color-grey-100);

  @media (--md) {
    position: absolute;
    z-index: var(--z-index-7);
    top: 0;
    right: 0;
    left: 0;
    height: var(--parent-height, 100vh);
    margin-top: unset;
  }

  &.isSticky {
    @media (--md) {
      right: $mdMargin;
      height: calc(var(--parent-height, 0%) + 200vh);
    }
  }

  .closeButton {
    display: none;
    @media (--md) {
      position: absolute;
      z-index: var(--z-index-2);
      top: var(--spacer-40);
      right: var(--spacer-40);
      width: var(--spacer-40);
      height: var(--spacer-40);
      box-shadow: var(--button-box-shadow);
      opacity: 0;
    }
  }
}

.mouseDistanceWrapper {
  --video-thumbnail-aspect-ratio: 1.82;

  @media (--md) {
    position: absolute;
    z-index: var(--z-index-2);
    top: calc(100vh - ($mdVideoWidth / var(--video-thumbnail-aspect-ratio)) - $mdMargin);
    right: $mdMargin;
    bottom: $mdMargin;
    width: $mdVideoWidth;
    pointer-events: all;
  }

  .isSticky & {
    @media (--md) {
      position: sticky;
      left: 100%;
    }
  }
}

.perspectiveWrapper {
  composes: cardAndButtonHover from '~g';
  position: relative;

  @media (--md) {
    aspect-ratio: var(--video-thumbnail-aspect-ratio, 16 / 9);
    perspective: 400px;
  }

  .floatingVideoPreview {
    position: relative;
    border-radius: 1rem;
    aspect-ratio: var(--preview-video-aspect-ratio, 1);
    grid-column: wrapper-start / wrapper-end;

    /* This is a trick to fix a rendering issue due to the combination of overflow: hidden and border-radius */
    mask-image: radial-gradient(white, black);

    @media (--md) {
      position: absolute;
      overflow: hidden;
      aspect-ratio: unset;
      inset: 0;
      opacity: 0;
      transform-origin: bottom;

      &::before {
        position: absolute;
        z-index: var(--z-index-1);
        display: block;
        border: 1px solid var(--color-alpha-light-10);
        border-radius: 1.2rem;
        content: '';
        inset: 0;
        pointer-events: none;
      }
    }

    .playButton {
      width: var(--spacer-40);
      height: var(--spacer-40);
    }
  }
}

.shadow {
  display: none;
  @media (--md) {
    display: block;
    opacity: 0;
    transform-origin: bottom;
  }
}

.video {
  --aspect-ratio: var(--preview-video-aspect-ratio, 0.8);
  @media (--md) {
    --aspect-ratio: unset;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%) scale(1.3);
  }
}

.thumbnail {
  z-index: var(--z-index-1);
  transition: opacity var(--duration-500ms);

  &.hidden {
    opacity: 0;
  }
}

.overlay {
  composes: grid from '~g';
  position: absolute;
  z-index: var(--z-index-2);
  inset: 0;

  @media (--md) {
    display: block;
  }
}

.dim {
  position: absolute;
  inset: 0;
}

.ui {
  position: absolute;
  bottom: var(--spacer-24);
  width: 100%;
  grid-column: start / end;

  @media (--md) {
    bottom: var(--spacer-16);
    padding: 0 1.6rem;
  }
}

.modalPlaceholder {
  position: fixed;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  padding: 5vh 5vw;
  inset: 0;
  pointer-events: none;
}

.modalVideoPlaceholder {
  width: 100%;
  height: 100%;
  align-self: center;
  aspect-ratio: var(--aspect-ratio);
}

.background {
  position: absolute;
  z-index: var(--z-index-1);
  background-color: var(--color-grey-00);
  opacity: 0;
  pointer-events: none;
}

.floatingVideoPreviewWrapper:not(.isSticky) {
  .floatingVideoPreview,
  .shadow {
    opacity: 1;
  }
}
