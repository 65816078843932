.announcementBanner {
  position: relative;
  display: flex;
  width: 100%;
  min-height: var(--announcement-banner-height);
  padding: var(--spacer-12) var(--spacer-24);
  background: var(--color-accent-banner, linear-gradient(355deg, rgb(255 255 255 / 15%) 4.03%, rgb(255 255 255 / 0%) 95.81%), #5b53ff);
  text-align: left;

  --banner-text-color: var(--color-grey-100);

  .closeButton {
    position: absolute;
    top: 50%;
    right: 1rem;
    width: 2.4rem;
    height: 2.4rem;
    transform: translateY(-50%);
  }

  .closeButtonInner {
    width: auto;
    height: auto;
  }

  path {
    fill: var(--banner-text-color, var(--color-grey-100));
  }
}

.prefersDarkText {
  --banner-text-color: var(--color-grey-00);
}
