@value nav600 from '~customBreakpoints';
@value navShowDesktop from '~customBreakpoints';
$cornerRadius: 20px;

.navigation {
  --nav-saturate: 150%;
  --nav-blur: 125px;
  --nav-filter: saturate(var(--nav-saturate)) blur(var(--nav-blur));
  --horizontal-padding: var(--grid-margin-size);
  --bottom-padding: var(--spacer-24);
  position: fixed;
  z-index: var(--z-index-nav);
  top: 0;
  width: 100%;

  @media nav600 {
    --horizontal-padding: var(--spacer-24);
    @media (--lg) {
      --horizontal-padding: var(--spacer-48);
    }
  }

  @media navShowDesktop {
    --bottom-padding: 12.4rem;
  }
}

.navigationWrapper {
  position: absolute;
  width: 100%;
  transform: translateY(0%);
  transition: transform var(--duration-500ms) var(--ease-basic-butter);

  &.isHidden {
    transform: translateY(-100%);
  }
}

.navigationBannerEl {
  z-index: var(--z-index-2);
  justify-content: center;
}

.mainNavigation {
  position: relative;
  display: flex;
  width: 100%;
  height: var(--nav-height);
  justify-content: center;
  pointer-events: auto;
}

.navigationInner {
  composes: grid from '~g';
  position: relative;
  z-index: var(--z-index-1);
  width: 100%;
  max-width: 144rem;
  height: 100%;
  align-items: center;
  justify-content: space-between;

  @media (hover: hover) {
    &:has(.navigationMainItem:hover, :global(.navGroupScrollableContainer):hover) {
      .navigationMainItem {
        color: var(--color-alpha-light-50);
      }
    }
  }
}

.left {
  position: absolute;
  z-index: var(--z-index-2);
  left: var(--horizontal-padding);
}

.logoLeft {
  display: flex;
  align-items: center;
}

.logo {
  position: relative;
  width: auto;
  height: 3.4rem;

  path {
    fill: var(--color-grey-97);
  }
}

.navigationMainItem {
  composes: navigation from '~typo';
  position: relative;
  z-index: var(--z-index-2);
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;

  /* mobile initial state (before animation) */
  transform: translateX(50%);
  transition: color var(--duration-300ms) var(--ease-out);

  &:global(.active),
  &:hover {
    /* stylelint-disable-next-line declaration-no-important */
    color: var(--color-grey-100) !important;
  }

  .isReducedNav & {
    transform: unset;
  }

  @media navShowDesktop {
    transform: unset;
  }

  /* helps with hover states bewteen items */
  &::before {
    position: absolute;
    top: 0;
    left: calc(var(--spacer-28) * -0.5);
    display: block;
    width: calc(100% + var(--spacer-28));
    height: 100%;
    content: '';
  }
}

.navigationButton {
  position: relative;
  z-index: var(--z-index-2);
}

.navigationMainItemInner {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.desktopNavigation {
  display: none;

  @media navShowDesktop {
    left: 0;
    display: flex;
    width: 100%;
    height: var(--nav-height);
    gap: var(--spacer-28);
    grid-column: start/end;
  }

  &.isHidden {
    display: none;
  }
}

.right {
  --icon-size: 2rem;
  position: absolute;
  z-index: var(--z-index-2);
  right: var(--horizontal-padding);
  display: none;
  flex-shrink: 0;
  align-items: center;
  gap: var(--spacer-28);

  .isReducedNav & {
    position: absolute;
    z-index: var(--z-index-2);
    display: flex;
    flex-shrink: 0;
    align-items: center;
    gap: var(--spacer-28);
  }

  @media navShowDesktop {
    display: flex;
  }

  .adobeLogo {
    display: none;
    @media (--lg) {
      display: initial;
    }
  }
}

.backdropSubnavContainer {
  position: absolute;
  left: 0;
  display: none;
  width: 100%;
  min-height: var(--nav-height);
  pointer-events: none;
  transform-origin: 0 0;
  @media navShowDesktop {
    top: 0;
    display: block;
  }
}

.backdrop {
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 100%;
  border-radius: 0;
  backdrop-filter: var(--nav-filter);
  background: var(--color-alpha-dark-30);
  transition: border-radius 0.2s 0s;

  .subNavOpen & {
    border-bottom-left-radius: $cornerRadius;
    border-bottom-right-radius: $cornerRadius;
    transition: border-radius 0.55s 0.1s;
  }
}

.backdropMainNav {
  z-index: var(--z-index-neg);

  /**
  * NOTE: On mobile browsers resizing can cause content above the viewport to be momentarily visible. This positioning
  * and the expanded height below ensures the blurred backdrop extends above the nav to avoid seeing unblurred content.
  */
  top: -5rem;
  height: calc(100% + 5rem);

  @media navShowDesktop {
    display: none;
  }
}

.backgroundDim {
  position: fixed;
  z-index: var(--z-index-neg);
  width: 100vw;
  height: 100vh;
  background: var(--overlay-dim60);
  opacity: 0;
  pointer-events: none;
  transition: opacity var(--duration-600ms) ease-out;

  .subNavOpen & {
    opacity: 1;
    pointer-events: all;
  }
}

.shadow {
  position: absolute;
  z-index: var(--z-index-neg);
  top: 0;
  width: 100%;
  height: $cornerRadius;
  margin-top: calc(-1 * $cornerRadius * 2);
  box-shadow: 0 27px 100px 65px rgb(0 0 0 / 80%);
  opacity: 0;
}
