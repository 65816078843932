.playWithLabelButton {
  --button-gap: var(--spacer-12);
  @media (hover: hover) {
    &:hover {
      --item-hover-progress: 1;

      /* this button can be a ui button (to play a video) or a cta (open a video modal). ui buttons have --buton-hover-color and ctas have --button-color so we need both here */
      path {
        fill: var(--button-hover-color, var(--button-color));
      }
    }
  }
}
