.base {
  composes: button from '~typo';
  position: relative;
  z-index: var(--z-index-1);
  display: inline-flex;

  /* switched from fixed padding to fixed height to ensure height is consistent */
  height: var(--button-height);
  align-items: center;
  padding: 0 var(--spacer-24);
  border-radius: var(--button-border-radius, 10rem);
  cursor: pointer;
  gap: var(--button-gap, var(--spacer-16));
  text-decoration: none;
  transition: color 300ms var(--ease-micro-bounce);
  white-space: nowrap;

  &.hasRightArrow {
    --button-gap: var(--spacer-6);
  }

  &.hasIconOnly {
    width: 5rem;
    height: 5rem;
    padding: 0;
  }

  &.primaryWithIcon {
    &.hasIconLeft {
      padding: var(--spacer-6) var(--spacer-24) var(--spacer-6) var(--spacer-6);
    }

    &.hasIconRight {
      padding: var(--spacer-6) var(--spacer-6) var(--spacer-6) var(--spacer-24);
    }
  }

  --button-disabled-bg-color: var(--color-alpha-light-10);
  --button-disabled-color: var(--color-grey-40);
  --button-disabled-border-color: var(--color-grey-100);
  --icon-size: 1em;

  a:focus:not(:disabled) &,
  button:focus:not(:disabled) &,
  &:focus:not(:disabled) {
    --button-hover-progress: 1;

    .buttonPlay {
      --item-hover-progress: 1;

      path {
        fill: var(--button-hover-color);
      }
    }
  }
  @media (hover: hover) {
    a:hover:not(:disabled) &,
    button:hover:not(:disabled) &,
    &:hover:not(:disabled) {
      --button-hover-progress: 1;

      .buttonPlay {
        --item-hover-progress: 1;

        path {
          fill: var(--button-hover-color);
        }
      }
    }
  }

  &::before {
    position: absolute;
    z-index: var(--z-index-neg);
    border: 1px solid var(--button-border-color);
    border-radius: inherit;
    background: var(--button-bg-color);
    content: '';
    inset: 0;
    transition: background 300ms var(--ease-micro-bounce);
  }
}

.disabled {
  cursor: not-allowed;

  --button-border-color: var(--button-disabled-border-color);
}

.basePrimary {
  justify-content: center;
  color: var(--button-color);

  &::after {
    position: absolute;
    z-index: var(--z-index-neg);
    border-radius: inherit;
    content: '';
    inset: 0;
    transition-duration: var(--duration-300ms);
    transition-property: box-shadow;
    transition-timing-function: var(--ease-micro-bounce);
  }

  @media (hover: hover) {
    a:not(.disabled):focus &,
    button:not(.disabled):focus &,
    &:not(.disabled):focus,
    a:not(.disabled):hover &,
    button:not(.disabled):hover &,
    &:not(.disabled):hover {
      &::before {
        background: var(--button-hover-bg-color);
      }
    }
  }

  a:not(.disabled):active &,
  button:not(.disabled):active &,
  &:not(.disabled):active {
    color: var(--button-active-color);

    &::before {
      background: var(--button-active-bg-color);
    }

    &::after {
      box-shadow: inset 0 0 0 0.4rem var(--button-active-border-color);
    }
  }

  a.disabled &,
  &.disabled {
    color: var(--button-disabled-color);

    &::before {
      background-color: var(--button-disabled-bg-color);
    }

    &::after {
      display: none;
    }
  }
}

.baseSolid {
  &::after {
    /* inset border */
    box-shadow: inset 0 0 0 0.2rem var(--button-border-color);
    opacity: 0;
    transition-property: opacity;
  }

  a:not(.disabled):hover &,
  button:not(.disabled):hover &,
  &:not(.disabled):hover {
    &::after {
      box-shadow: inset 0 0 0 0.2rem var(--button-hover-border-color);
      opacity: 1;
    }
  }

  a:not(.disabled):active &,
  button:not(.disabled):active &,
  &:not(.disabled):active {
    &::before {
      border: 0;
    }

    &::after {
      box-shadow: inset 0 0 0 0.4rem var(--button-active-border-color);
      opacity: 1;
    }
  }
}

.baseGlass {
  &::before {
    border: 0;
    backdrop-filter: blur(15px);
  }

  &::after {
    /* inset border */
    box-shadow: inset 0 0 0 0.1rem var(--button-border-color);
  }

  @media (hover: hover) {
    a:not(.disabled):hover &,
    button:not(.disabled):hover &,
    &:not(.disabled):hover {
      &::after {
        box-shadow: inset 0 0 0 0.2rem var(--button-hover-border-color);
      }
    }
  }

  a:not(.disabled):active &,
  button:not(.disabled):active &,
  &:not(.disabled):active {
    &::before {
      background: var(--button-active-bg-color);
    }

    &::after {
      box-shadow: inset 0 0 0 0.4rem var(--button-active-border-color);
    }
  }
}

.glass {
  composes: base;
  composes: basePrimary;
  composes: baseGlass;

  --button-bg-color: var(--color-alpha-light-20);
  --button-color: var(--color-grey-100);
  --button-border-color: var(--color-alpha-light-5);
  --button-hover-bg-color: var(--color-alpha-light-30);
  --button-hover-border-color: var(--color-alpha-light-10);
  --button-active-bg-color: transparent;
  --button-active-border-color: var(--color-alpha-light-20);
  --button-icon-bg-color: var(--button-glass-bg);
}

.ghost {
  composes: base;
  composes: basePrimary;
  composes: baseGlass;

  --button-bg-color: transparent;
  --button-color: var(--color-grey-100);
  --button-border-color: var(--color-alpha-light-20);
  --button-hover-border-color: var(--color-alpha-light-10);
  --button-hover-bg-color: var(--color-alpha-light-30);
  --button-active-bg-color: transparent;
  --button-active-border-color: var(--color-alpha-light-20);
  --button-icon-bg-color: var(--button-glass-bg);
}

.white {
  composes: base;
  composes: basePrimary;
  composes: baseSolid;

  --button-bg-color: var(--color-grey-97);
  --button-color: var(--color-grey-00);
  --button-border-color: var(--color-grey-80);
  --button-hover-bg-color: var(--color-alpha-light-70);
  --button-hover-border-color: var(--color-grey-100);
  --button-active-color: var(--color-grey-100);
  --button-active-bg-color: transparent;
  --button-active-border-color: var(--color-alpha-light-20);
}

.textLink {
  composes: base;
  height: auto;
  padding: 0;
  border-radius: 0;
  color: var(--button-text-color, var(--color-grey-97));
  line-height: 1;
  place-self: center;

  @media (--md) {
    place-self: center start;
  }
}

.buttonText {
  flex-grow: 1;
  text-align: center;

  .hasRightArrow & {
    flex-grow: 0;
  }
}

.phoneNumber {
  padding-right: var(--spacer-16);
}
