@value navShowDesktop from '~customBreakpoints';

.container {
  display: initial;

  &.isHidden {
    display: none;
  }
}

.navMobile {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  width: 100vw;

  /* resolves issue with mobile safari not calculating the viewport height properly, causing the address bar to cover up the bottom of content */
  height: calc(var(--vh, 1vh) * 100 - var(--banner-height, 0px));
  padding-top: var(--banner-height, 0);
  pointer-events: none;

  &.open {
    pointer-events: all;
  }

  @media navShowDesktop {
    display: none;
  }

  /* fixes safari ios rendering issue with backdrop blur */
  .backdropMobile {
    z-index: var(--z-index-neg);
    top: 0;
    min-width: 1px;
    min-height: 1px;
    backdrop-filter: blur(var(--nav-blur));
    background: var(--color-alpha-dark-80, rgb(13 13 24 / 80%));
  }
}

.navMobileOverlay {
  display: none;

  /* fix subpixel spacing issue when scaled down */
  width: calc(100% + 0.1rem);
  height: 100%;
  background: var(--color-alpha-black-90);
  opacity: 0;
  pointer-events: none;
  transform-origin: 0 0;

  @media navShowDesktop {
    position: absolute;
    display: block;
  }
}

.navMobileWrapper {
  position: absolute;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
  visibility: hidden;
}

.navMobileContent {
  composes: grid from '~g';
  height: 100%;
  padding-top: calc(var(--nav-height) + var(--spacer-32));
  list-style: none;
}

.navMobileMainLinks {
  display: flex;
  flex-direction: column;
  gap: var(--spacer-24);
  transform-origin: 0 0;
}

.navMobileItems {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  grid-column: start / end;
}

.navMobileBottomLinks {
  display: flex;
  margin-bottom: var(--spacer-48);
  gap: var(--spacer-12);
  place-content: center center;

  /* mobile initial state (before animation) */
  transform: translateX(50%);
}

.navMobileRight {
  position: absolute;
  z-index: var(--z-index-nav);
  top: 50%;
  right: var(--horizontal-padding);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--spacer-24);
  transform: translateY(-50%);
  transform-origin: 50% 50%;

  @media navShowDesktop {
    display: none;
  }
}

.bottomButton {
  flex-basis: 17rem;
}

.textLink {
  margin: auto 0;
}
