.glow {
  position: absolute;
  z-index: var(--z-index-neg);
  width: 100%;
  height: 100%;
  inset: 0;
}

.glow,
.shadow {
  display: none;

  @media (--md) {
    display: block;
  }
}

.slide {
  position: relative;
  overflow: hidden;
  min-width: 100vw;
  max-width: 100vw;
  min-height: 93vh;
  aspect-ratio: var(--slide-aspect-ratio);
  transition: opacity 0.4s var(--ease-out);

  &.twoUp {
    --slide-aspect-ratio: 0.75;
    min-width: unset;
    max-width: unset;
    min-height: unset;
    border-radius: var(--border-radius-10);
  }

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    display: block;
    height: 15rem;
    background: linear-gradient(180deg, #000000 0%, rgb(0 0 0 / 0%) 100%);

    @media (--md) {
      content: '';
    }
  }

  @media (--md) {
    overflow: unset;
    min-width: unset;
    max-width: unset;
    min-height: inherit;
    border-radius: unset;

    --slide-aspect-ratio: 1.96;

    &.twoUp {
      --slide-aspect-ratio: 1.5;
    }

    &::after {
      display: none;
    }
  }

  &[data-active] {
    opacity: 1;
  }
}

.slideInner {
  composes: grid from '~g';
  overflow: hidden;
  height: 100%;
  padding: var(--slide-padding);

  --slide-padding: 0 0 var(--spacer-40);
  place-content: end start;

  @media (--md) {
    --slide-padding: 0 0 var(--spacer-48);
    border-radius: var(--border-radius-10);

    /* the grid works a little differently due the keen slider. we want this to be a regular grid on sm, and a subgrid on md and lg, but composes subgrid will apply it to sm as well. therefore we need to reset the template columns to take the subgrid settings here */
    grid-template-columns: [start] repeat(var(--grid-number-of-columns), [col] 1fr) [end];
  }

  @media (--lg) {
    --slide-padding: 0 0 var(--spacer-64);
  }

  .twoUp & {
    --slide-padding: 2.4rem;
    display: flex;
  }
}

.slideTextLockup {
  z-index: var(--z-index-2);
  opacity: 0;
  transition: opacity var(--duration-500ms) var(--ease-basic-butter);

  .isActive & {
    opacity: 1;
  }

  @media (--md) {
    opacity: 0.2;

    .isActive & {
      opacity: 1;
    }
  }

  &.oneCard {
    --title-column-start: col 1;
    --graphic-eyebrow-start: col 1;
    --graphic-eyebrow-margin: var(--spacer-16);

    @media (--md) {
      --title-column-start: col 2;
      --graphic-eyebrow-start: col 2;
    }
  }

  &.twoUp {
    --title-column-start: 1;
    --grid-number-of-columns: 6;

    /* two up slides always have 6 columns, their content will not stretch more than 6 */
    grid-template-columns: [start] repeat(var(--grid-number-of-columns), [col] 1fr) [end];

    --graphic-eyebrow-start: 1;
  }

  .twoUp & {
    @media (--md) {
      width: 100%;
    }
  }
}

.slideImageWrapper {
  z-index: auto;
  backface-visibility: hidden;

  @media (--sm-only) {
    .oneCard & {
      transform: scale(calc((1 - var(--scroll-progress)) * 0.3 + 1)) translate3d(calc((1 - var(--scroll-progress)) * 10%), 0, 0);
    }
  }
  @media (--md) {
    height: auto;
  }
}

.slideImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.slideGlow {
  --glow-translate-x: -50%;
  --glow-translate-y: -40%;
}

.slideImageWrapper,
.slideImageOverlay,
.inactiveOverlayLayerDim {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  @media (--md) {
    overflow: hidden;
    border-radius: var(--border-radius-10);
  }
}

.inactiveOverlayLayerDim {
  z-index: 2;
  opacity: 0.5;
  transition: opacity var(--duration-500ms) var(--ease-basic-butter);

  .isActive & {
    opacity: 0;
  }

  @media (--md) {
    opacity: 1;

    .isActive & {
      opacity: 0;
    }
  }
}

.oneCardCarouselOverlay {
  position: absolute;
  z-index: var(--z-index-1);
  display: none;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  transform: translateZ(0);
}

/* on small, show the overlay that covers the entire carousel and hide the individual overlays for the images */
.oneCard {
  @media (--sm-only) {
    .oneCardCarouselOverlay {
      display: block;
    }

    .slideImageOverlay {
      display: none;
    }
  }
}
