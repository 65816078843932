.glassWrapper {
  padding: var(--glass-wrapper-border);
  border: 1px solid var(--glass-border-color, var(--color-alpha-light-5));
  border-radius: var(--border-radius-6);
  backdrop-filter: blur(16px);
  background-color: var(--color-alpha-light-5);

  @media (--md) {
    border-radius: var(--border-radius-10);
  }
}

.glassWrapperContent {
  mask-image: var(--top-bottom-mask-3px);

  @media (--md) {
    mask-image: var(--top-bottom-mask-5px);
  }
}
