.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.title {
  margin-top: 0.2rem;
}
