.content {
  position: relative;
}

.titles {
  color: var(--color-grey-100);
}

.titles + .bodies {
  margin-top: var(--spacer-4);
}

.bodies + .buttonGroup {
  margin-top: var(--spacer-32);
}

.buttonGroup + .buttonGroup {
  margin-top: var(--spacer-32);
}
