.content {
  grid-column: start/end;
  text-align: left;
  @media (--md) {
    grid-column: var(--lockup-grid-start, start) / span calc(var(--lockup-column-span, 4) + 1);
  }
  @media (--lg) {
    grid-column: var(--lockup-grid-start, start) / span var(--lockup-column-span, 4);
  }
}

.titles + .titles,
.titles + .bodies {
  margin-top: var(--spacer-6);

  @media (--md) {
    margin-top: var(--spacer-10);
  }
  @media (--lg) {
    margin-top: var(--spacer-12);
  }
}

.bodies + .titles {
  margin-top: var(--spacer-40);

  @media (--md) {
    margin-top: var(--spacer-48);
  }
}

.buttonGroup {
  margin-top: var(--spacer-24);
}
