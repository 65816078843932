.content {
  composes: content from './TextLockup.module.css';

  --all-lockup-column-span: var(--grid-number-of-columns);
  @media (--md) {
    --all-lockup-column-span: var(--lockup-column-span, 10);
  }
}

.titles {
  grid-column: start / end;

  @media (--md) {
    grid-column: 2 / span 10;
  }
}
