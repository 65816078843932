.content {
  composes: content from './TextLockup.module.css';
  display: flex;
  flex-direction: column;
}

.graphic {
  margin: var(--spacer-64) 0 var(--spacer-54);

  @media (--md) {
    margin: var(--spacer-48) 0 var(--spacer-32);
  }
}

.titles {
  composes: title4 from '~typo';
  margin: var(--spacer-16) 0 var(--spacer-12);
}

.emWithDash {
  composes: labelEm from '~deco';
}

.openQuote {
  composes: indentTitle4Quote from '~deco';
  display: inline-block;
}

.accents {
  composes: accents from './TextLockup.module.css';
}
