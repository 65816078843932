.content {
  grid-column: start / end;
  text-align: left;
}

.bodies {
  composes: body from '~typo';
  margin-bottom: var(--spacer-8);
  color: var(--color-alpha-light-50);

  @media (--md) {
    display: block;
  }

  strong {
    color: var(--color-grey-88);
  }

  &:last-child {
    margin-bottom: 0;
  }
}
