.content {
  grid-column: start/end;
  text-align: left;
  @media (--md) {
    grid-column: col calc((var(--grid-number-of-columns) / 2 - var(--lockup-column-span) / 2) + 1) / span var(--lockup-column-span);
    text-align: center;
  }
}

.bodies {
  composes: bodyExtraLarge from '~typo';
  color: var(--color-grey-100);
}

.buttonGroup {
  margin-top: var(--spacer-24);
  @media (--md) {
    justify-content: center;
    margin-top: var(--spacer-32);
  }
  @media (--lg) {
    margin-top: var(--spacer-48);
  }
}
