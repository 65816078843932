.buttonGroup {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  gap: var(--spacer-16);
  @media (--lg) {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
  }

  &:last-child {
    margin-bottom: 0;
  }
}
