.navMobileButton {
  width: 2rem;
  height: 2.5rem;
  backface-visibility: hidden;
  pointer-events: all;
}

.navMobileButtonLine {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  overflow: hidden;
  transform: translate(-50%, -50%);
  transform-origin: 50% 50%;

  .navMobileButtonLineEl {
    position: absolute;
    left: 0;
    width: 100%;
    border-radius: 0.7em;
    background: var(--color-grey-95);
    transition: all 0.2s ease-out;
  }

  &:nth-child(1) {
    transform: translate(-50%, -50%) rotate(-45deg);

    .navMobileButtonLineEl {
      transform-origin: 0 0;
    }
  }

  &:nth-child(2) {
    transform: translate(-50%, -50%) rotate(45deg);

    .navMobileButtonLineEl {
      transform-origin: 0 0;
    }
  }

  &:nth-child(3) {
    transform: translate(-50%, -50%) rotate(-45deg);

    .navMobileButtonLineEl {
      bottom: 0;
      transform-origin: 0 100%;
    }
  }

  &:nth-child(4) {
    transform: translate(-50%, -50%) rotate(45deg);

    .navMobileButtonLineEl {
      bottom: 0;
      transform-origin: 0 100%;
    }
  }
}
