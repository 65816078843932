$initialTranslateY: 72px;

.bentos {
  composes: grid from '~g';
}

.cardsGrid {
  display: grid;
  gap: var(--spacer-16);
  grid-column: start / end;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;

  @media (--md) {
    gap: var(--spacer-24);
    grid-template-columns: repeat(12, 1fr);
  }
}

.bentoCardWrapper {
  grid-column: span 4;
  grid-row: span 1;

  @media (--md) {
    flex: 0 0 100%;
  }

  @media (--md) {
    grid-column: span var(--bento-column) / span var(--bento-column);
    grid-row: span var(--bento-row) / span var(--bento-row);
  }
}

/* animated bento initial state */
.animatedBento {
  opacity: 0;

  /* translate 1px z to force z-index to be applied on safari */
  transform: translate3d(0, $initialTranslateY, 1px);
}
