.content {
  grid-column: start/end;
  @media (--md) {
    grid-column: var(--lockup-grid-start, start) / span calc(var(--lockup-column-span, 4) + 1);
  }
  @media (--lg) {
    grid-column: var(--lockup-grid-start, start) / span var(--lockup-column-span, 4);
  }
  text-align: var(--lockup-alignment, left);
}

.bodies {
  composes: bodyMedium from '~typo';
}

.accents {
  composes: accents from './TextLockup.module.css';
}

.eyebrow + .titles {
  margin-top: var(--spacer-12);
}

.titles + .bodies {
  margin-top: var(--spacer-12);
}

.buttonGroup {
  justify-content: var(--lockup-justify, flex-start);
  margin-top: var(--spacer-24);
  gap: var(--spacer-16);
}
