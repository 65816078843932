.content {
  position: relative;
  margin-bottom: var(--spacer-32);
}

.titles,
.accents {
  color: var(--color-grey-100);
}

.titles + .bodies {
  margin-top: var(--spacer-4);
}

.bodies + .buttonGroup {
  margin-top: var(--spacer-16);
}

.accents {
  composes: accents from './TextLockup.module.css';
}
