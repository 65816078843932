.wrapper {
  composes: grid from '~g';
}

.hugeStatsWrapper {
  position: relative;
}

.container {
  composes: grid from '~g';
  position: relative;
  z-index: var(--z-index-1);
}

.columnLeft {
  position: relative;
  margin-bottom: var(--spacer-48);
  grid-column: start / end;

  @media (--md) {
    position: sticky;
    top: calc(var(--nav-height) + var(--spacer-24));
    margin-bottom: 0;
    grid-column: col 2 / span 4;
  }
  @media (--lg) {
    grid-column: col 2 / span 3;
  }
}

.content {
  @media (--md) {
    padding-top: var(--spacer-24);
  }
}

.bodies {
  composes: bodyMedium from '~typo';
}

.columnRight {
  grid-column: start / end;

  @media (--md) {
    grid-column: col 7 / span 6;
    grid-row: 1 / span 9;
  }
  @media (--lg) {
    grid-column: col 7 / span 6;
  }

  .stats {
    margin-top: 0;

    @media (--md) {
      --subgrid-start: col 1;
      --subgrid-width: 6;
    }
  }
}
