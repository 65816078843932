.container {
  composes: grid from '~g';
  position: relative;
}

.backgroundGlow {
  position: absolute;
  z-index: var(--z-index-neg);
  inset: 0;
  transform: translate3d(0, 25%, 0);

  @media (--sm) {
    transform: translate3d(0, 40%, 0);
  }
}
