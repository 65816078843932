.groupLabel {
  composes: eyebrow from '~typo';
  margin-bottom: var(--spacer-16);
  color: var(--color-alpha-light-70);
}

.linkItemWrapper {
  display: flex;
  list-style: none;

  &:last-child {
    .linkItem {
      padding-right: unset;
      padding-bottom: unset;
    }
  }
}

.linkItem {
  composes: label from '~typo';
  display: block;

  /* use padding instead of gap for seamless hover transitions when mousing over items */
  padding-right: var(--grid-gutter-size);
  padding-bottom: var(--spacer-16);
  transition: color var(--duration-300ms) var(--ease-out);
}
