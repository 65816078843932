.content {
  composes: content from './TextLockup.module.css';
  grid-column: start/end;

  --title-4-pothole-column-span: var(--grid-number-of-columns);

  @media (--md) {
    --title-4-pothole-column-span: var(--lockup-column-span, 5);
  }
}

.accents,
.titles,
.types {
  grid-column: start / span var(--title-4-pothole-column-span);

  @media (--md) {
    grid-column: var(--title-4-pothole-column-start, col 2) / span var(--title-4-pothole-column-span);
  }
}

.graphic {
  & + * {
    margin-top: var(--spacer-24);
  }

  * {
    fill: var(--color-grey-100);
  }
}

.eyebrow {
  & + * {
    margin-top: var(--spacer-12);
  }
}

.openQuote {
  composes: indentTitle4Quote from '~deco';
  display: inline-block;
}

.titles {
  composes: title4 from '~typo';
}

* + .label {
  margin-top: var(--spacer-24);

  @media (--lg) {
    margin-top: var(--spacer-32);
  }
}

.emWithDash {
  composes: labelEm from '~deco';
}

.ctas {
  display: flex;
  gap: var(--spacer-16);

  * + & {
    margin-top: var(--spacer-24);
  }
}

.accents {
  composes: accents from './TextLockup.module.css';
}

.label {
  color: var(--color-grey-100);
}
