.content {
  z-index: 2;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-self: flex-start;
}

.label {
  composes: label from '~typo';
  margin-top: var(--spacer-12);
  color: var(--color-alpha-light-50);
}

.title {
  composes: titleStatistic from '~typo';
}

.logo,
.eyebrow {
  margin-top: 0;
  margin-bottom: auto;
  color: var(--color-alpha-light-50);
}
