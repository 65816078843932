.contentNestedInGrid {
  grid-column: start/end;
  @media (--md) {
    grid-column: var(--lockup-grid-start, start) / span var(--lockup-column-span, 4);
  }
  @media (--lg) {
    grid-column: var(--lockup-grid-start, start) / span var(--lockup-column-span, 4);
  }
  text-align: var(--lockup-alignment, left);
}

.content {
  composes: content from './TextLockup.module.css';
  text-align: var(--lockup-alignment);

  --title-1-column-span: var(--grid-number-of-columns);
  @media (--md) {
    --title-1-column-span: var(--lockup-column-span);
  }

  .accents,
  .titles,
  .bodies,
  .types {
    grid-column: start / span var(--title-1-column-span);
  }

  .types {
    justify-content: var(--lockup-justify, start);
    margin-top: var(--spacer-24);
  }

  .buttonGroup {
    /* Equal width buttons */
    @media (--md) {
      display: grid;
      grid-auto-flow: column;
      grid-template-columns: repeat(var(--nb-buttons), minmax(auto, 1fr));
      justify-self: var(--lockup-justify, start);
    }
  }
}

/* bodies abide by different rules */
.bodies {
  composes: bodyMedium from '~typo';
  color: var(--grey-brand-grey-60, #81859f);

  @media (--md) {
    :global(.textLockupColumns8).content & {
      --title-1-column-span: calc(var(--lockup-column-span) - 1);
    }

    :global(.textLockupColumns9).content & {
      --title-1-column-span: calc(var(--lockup-column-span) - 2);
    }

    :global(.textLockupColumns10).content & {
      --title-1-column-span: calc(var(--lockup-column-span) - 3);
    }

    :global(.textLockupColumns11).content & {
      --title-1-column-span: calc(var(--lockup-column-span) - 4);
    }

    :global(.textLockupColumns12).content & {
      --title-1-column-span: calc(var(--lockup-column-span) - 5);
    }
  }
}

:global(.textLockupCenter).content {
  @media (--md) {
    .accents,
    .titles,
    .bodies,
    .types {
      grid-column: col calc((var(--grid-number-of-columns) / 2 - var(--title-1-column-span) / 2) + 1) / span var(--title-1-column-span);
    }

    &:global(.textLockupColumns10) .bodies {
      --title-1-column-span: calc(var(--lockup-column-span) - 2);
    }

    &:global(.textLockupColumns12) .bodies {
      --title-1-column-span: calc(var(--lockup-column-span) - 4);
    }
  }
}

.accents {
  composes: accents from './TextLockup.module.css';
  margin-bottom: var(--spacer-12);
}

.titles {
  margin-bottom: var(--spacer-12);
}

.logo {
  margin-bottom: var(--spacer-24);
}
