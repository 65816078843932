.image {
  display: block;
  width: 100%;
  height: 100%;
  transition: opacity 0.4s;

  &.animated {
    opacity: 0;
  }

  &.isCover {
    object-fit: cover;
    object-position: var(--object-position);
  }
}
