.content {
  composes: content from './TextLockup.module.css';
  grid-column: start/end;
  text-align: var(--lockup-alignment, left);

  --title-3-column-span: var(--grid-number-of-columns);
  @media (--md) {
    --title-3-column-span: var(--lockup-column-span, 6);
  }
}

:global(.textLockupLeft).content {
  .titles,
  .bodies,
  .types {
    grid-column: var(--title-3-column-start, start) / span var(--title-3-column-span);
  }
}

:global(.textLockupCenter).content {
  .titles,
  .bodies,
  .types {
    grid-column: var(--title-3-column-start, start) / span var(--title-3-column-span);

    @media (--md) {
      grid-column: var(--title-3-column-start, col calc((var(--grid-number-of-columns) / 2 - var(--title-3-column-span) / 2) + 1)) / span var(--title-3-column-span);
    }
  }
}

.titles {
  composes: title3 from '~typo';
}

.bodies {
  composes: bodyMedium from '~typo';

  * + & {
    margin-top: var(--spacer-12);
  }

  @media (--md) {
    :global(.textLockupColumns8) &,
    :global(.textLockupColumns10) & {
      --title-3-column-span: calc(var(--lockup-column-span) - 2);
    }
  }
}

.buttonGroup {
  display: flex;
  justify-content: var(--lockup-justify, start);
  gap: var(--spacer-16);

  * + & {
    margin-top: var(--spacer-24);
  }
}

.accents {
  composes: accents from './TextLockup.module.css';
}
