.content {
  grid-column: start / end;
}

.titles {
  composes: title3 from '~typo';
  margin-top: var(--spacer-48);
  grid-column: start / end;
}

.bodies {
  composes: bodyMedium from '~typo';
  margin-top: var(--spacer-16);
  color: var(--grey-brand-grey-60, #81859f);
}

.buttonGroup {
  margin-top: var(--spacer-24);

  @media (--lg) {
    margin-top: var(--spacer-32);
  }

  a {
    color: var(--color-cobalt);

    &:hover {
      text-decoration: underline;
    }
  }
}

.link {
  color: var(--color-cobalt);
}
