.content {
  composes: content from './TextLockup.module.css';
  height: 100%;
  grid-column: start/end;
  grid-template-rows: 1fr auto;

  --title-carousel-block: var(--grid-number-of-columns);

  .graphic,
  .eyebrow {
    margin-bottom: var(--graphic-eyebrow-margin);
    grid-column: var(--graphic-eyebrow-start) / end;
  }

  .ctas,
  .label,
  .titles {
    grid-column: 1 / span var(--title-carousel-block);

    @media (--md) {
      grid-column: var(--title-column-start, col 2) / span var(--title-carousel-block);
    }
  }

  @media (--md) {
    /* for the two up, we have a limited number of columns (=6) we never want to exceed */
    --title-carousel-block: min(var(--grid-number-of-columns), var(--lockup-column-span, 5));
  }
}

.eyebrow {
  display: block;
  white-space: nowrap;
}

.openQuote {
  composes: indentTitle4Quote from '~deco';
  display: inline-block;
}

* + .label {
  margin-top: var(--spacer-16);
}

.emWithDash {
  composes: labelEm from '~deco';
}

.ctas {
  display: flex;
  gap: var(--spacer-16);
  justify-self: start;

  * + & {
    margin-top: var(--spacer-16);
  }
}
