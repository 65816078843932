.content {
  position: relative;

  .titles {
    color: var(--color-grey-100);
  }

  .bodies {
    color: var(--color-alpha-light-50);
  }

  .titles + .bodies {
    margin-top: var(--spacer-4);
  }

  .bodies + .buttonGroup {
    margin-top: var(--spacer-24);
  }

  .buttonGroup {
    justify-content: var(--lockup-justify, start);
  }
}
