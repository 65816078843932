.eyebrow {
  color: var(--color-accent);
}

.eyebrow + .titles {
  margin-top: var(--spacer-12);
}

.titles + .bodies {
  margin-top: var(--spacer-12);
}

.buttonGroup {
  justify-content: flex-start;
  margin-top: var(--spacer-24);
  gap: var(--spacer-16);

  @media (--md) {
    justify-content: var(--lockup-justify, flex-start);
  }
}

/* subgrid */
.content {
  composes: content from './TextLockup.module.css';
  text-align: left;

  --title-2-column-span: var(--grid-number-of-columns);
  @media (--md) {
    --title-2-column-span: var(--lockup-column-span);
    text-align: var(--lockup-alignment);
  }

  .accents,
  .titles,
  .bodies,
  .types {
    grid-column: start / span var(--title-2-column-span);
  }

  .bodies {
    color: var(--color-alpha-light-50);

    @media (--md) {
      --title-2-column-span: calc(var(--lockup-column-span) - 2);

      :global(.textLockupColumns8) & {
        --title-2-column-span: calc(var(--lockup-column-span) - 1);
      }

      :global(.textLockupColumns9) & {
        --title-2-column-span: calc(var(--lockup-column-span) - 2);
      }

      :global(.textLockupColumns10) & {
        --title-2-column-span: calc(var(--lockup-column-span) - 3);
      }

      :global(.textLockupColumns11) &,
      :global(.textLockupColumns12) & {
        --title-2-column-span: calc(var(--lockup-column-span) - 4);
      }
    }
  }

  .buttonGroup {
    display: flex;
    justify-content: flex-start;
    gap: var(--spacer-16);

    @media (--md) {
      justify-content: var(--lockup-justify, flex-start);
    }
  }
}

:global(.textLockupCenter).content {
  @media (--md) {
    .accents,
    .titles,
    .bodies,
    .types {
      grid-column: col calc((var(--grid-number-of-columns) / 2 - var(--title-2-column-span) / 2) + 1) / span var(--title-2-column-span);
    }

    .bodies {
      --title-2-column-span: calc(var(--lockup-column-span) - 2);

      :global(.textLockupColumns8) & {
        --title-1-column-span: calc(var(--lockup-column-span) - 2);
      }

      :global(.textLockupColumns10) &,
      :global(.textLockupColumns12) & {
        --title-1-column-span: calc(var(--lockup-column-span) - 4);
      }
    }
  }
}

.openQuote {
  @media (--md) {
    position: absolute;
    top: 0;
    right: 100%;
  }
}

.accents {
  composes: accents from './TextLockup.module.css';
}

.bodies {
  composes: bodyMedium from '~typo';
}
