.buttonCard {
  position: relative;
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 100%;
  min-height: 28rem;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: var(--spacer-32);
  border-radius: var(--border-radius-5);
  background: var(--card-background);

  @media (--md) {
    padding: var(--spacer-40);
  }

  &.blurredBackground {
    backdrop-filter: blur(var(--card-background-blur));
    background: var(--color-alpha-dark-50);
  }

  &::after {
    position: absolute;
    display: block;

    /* bento border is not using a color within design system */
    border: 1px solid var(--color-alpha-light-5);
    border-radius: var(--border-radius-5);
    content: '';
    inset: 0;
    pointer-events: none;
  }
}

.content {
  z-index: 2;
}
