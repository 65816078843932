.modulesContainer {
  position: relative;
  z-index: var(--z-index-2);
}

.module {
  grid-column: wrapper-start / wrapper-end;
}

.stats {
  composes: grid from '~g';
}

.spacer {
  display: block;
  grid-column: wrapper-start / wrapper-end;

  &.sm {
    @media (--md) {
      display: none;
    }
  }

  &.md {
    display: none;
    @media (--md) {
      display: block;
    }
    @media (--lg) {
      display: none;
    }
  }

  &.lg {
    display: none;
    @media (--lg) {
      display: block;
    }
    @media (--xl) {
      display: none;
    }
  }

  &.xl {
    display: none;
    @media (--xl) {
      display: block;
    }
  }
}
