.content {
  composes: content from './TextLockup.module.css';
  grid-column: start/end;

  --body-xl-block-column-span: var(--grid-number-of-columns);

  @media (--md) {
    --body-xl-block-column-span: var(--lockup-column-span, 5);
  }
}

.accents,
.bodyExtraLarge,
.bodies,
.types {
  grid-column: start / span var(--body-xl-block-column-span);

  @media (--md) {
    grid-column: var(--body-xl-block-column-start, col 2) / span var(--body-xl-block-column-span);
  }
}

.bodyExtraLarge {
  composes: bodyExtraLarge from '~typo';
  color: var(--color-grey-100);

  * + & {
    margin-top: var(--spacer-12);
  }
}

.graphic {
  margin-bottom: var(--body-xl-block-graphic-margin-bottom, 9rem);

  @media (--lg) {
    margin-bottom: var(--body-xl-block-graphic-margin-bottom, 11.2rem);
  }

  * {
    fill: var(--color-grey-100);
  }
}

.openQuote {
  composes: indentTitle4Quote from '~deco';
  display: inline-block;
}

* + .label {
  margin-top: var(--spacer-24);

  @media (--lg) {
    margin-top: var(--spacer-32);
  }
}

.bodies {
  * + & {
    margin-top: var(--spacer-12);
  }
}

.emWithDash {
  composes: labelEm from '~deco';
}

.ctas {
  display: flex;
  gap: var(--spacer-16);

  * + & {
    margin-top: var(--spacer-24);
  }
}
