.content {
  composes: content from './TextLockup.module.css';
}

.accents,
.titles,
.bodies,
.types,
.list {
  grid-column: start / end;

  @media (--md) {
    grid-column: start / span 7;
  }

  @media (--lg) {
    grid-column: start / span 5;
  }

  /* centered */

  .alignCenter & {
    @media (--md) {
      grid-column: col 3 / span 8;
    }

    @media (--lg) {
      grid-column: col 3 / span 8;
    }
  }
}

.accents {
  composes: accents from './TextLockup.module.css';
}

.titles {
  composes: titles from './TextLockup.module.css';
  margin-top: var(--spacer-12);

  & + & {
    margin-top: 0;
  }
}

.bodies {
  composes: bodies from './TextLockup.module.css';
  margin-top: var(--spacer-12);
  color: var(--color-bodies, var(--color-alpha-light-50));
}

.types {
  composes: types from './TextLockup.module.css';
  margin-top: var(--spacer-24);
}

.types + .forms {
  margin-top: var(--spacer-40);
}

.forms {
  .alignCenter & {
    @media (--md) {
      grid-column: col 4 / span 6;
    }

    @media (--lg) {
      grid-column: col 4 / span 6;
    }
  }
}

.subtitle {
  color: var(--color-alpha-light-40);
}
