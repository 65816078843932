.content {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-self: flex-start;
  gap: 0.8rem;
}

.titles,
.accents {
  color: var(--color-grey-100);
}

.titles + .bodies {
  margin-top: var(--spacer-4);
}

.bodies + .buttonGroup {
  display: flex;
  margin-top: auto;
}
