@value navShowDesktop from '~customBreakpoints';
@value minRows: 4;

.navGroup {
  display: flex;
  height: 100%;
  flex-flow: row nowrap;
}

.navGroupScrollableContainer {
  position: fixed;
  top: calc(var(--nav-height));
  left: 0%;
  overflow: hidden scroll;
  width: 100%;
  height: calc(100vh - var(--nav-height) - var(--bottom-padding) - var(--banner-height));
  pointer-events: none;

  .open & {
    pointer-events: all;
  }

  @media navShowDesktop {
    position: absolute;
    top: unset;
    left: 0;
    overflow: unset;
    width: 100%;
    height: auto;
    place-content: end;
  }
}

.navGroupSubnav {
  composes: grid from '~g';
  padding: var(--spacer-32) 0;

  --row-gap: var(--spacer-18);

  @media navShowDesktop {
    /* top padding: 96px between main nav and sub nav
    bottom padding: bottom padding minus extra row gap thats attached to the bottom of the last list items */
    padding: calc(var(--spacer-96) + var(--nav-height)) 0 calc(var(--bottom-padding) - var(--row-gap));
  }
}

.navGroup .backButton,
.navGroupSubgrid {
  z-index: 1;
}

.backButton {
  composes: title2 from '~typo';
  display: flex;
  align-items: center;
  margin-bottom: var(--spacer-40);
  font-family: inherit;
  font-size: inherit;
  gap: var(--spacer-24);
  grid-column: wrapper-start/wrapper-end;

  .navGroupCaret {
    transform: rotate(90deg);
  }
}

.navGroupLabel {
  composes: title2 from '~typo';
  color: var(--color-grey-100);
}

.navGroupLabelDesktop {
  display: none;
  margin-bottom: 5rem;
  grid-column: start/end;

  @media navShowDesktop {
    display: block;
  }
}

.navGroupLabelMobile {
  grid-column: start/end;

  @media navShowDesktop {
    display: none;
  }
}

.navGroupSubgrid {
  display: grid;
  padding: 0 0 var(--bottom-padding);
  grid-column: start/end;
  grid-gap: var(--spacer-40) var(--grid-gutter-size);
  grid-template-columns: [start] repeat(var(--subgrid-width, auto-fill), [col] 100%) [end];
  @media navShowDesktop {
    padding: unset;
    grid-column: start/end;
    grid-gap: unset;

    /* col min/max units attempt to balance the columns so they don't get too wide or too narrow */
    grid-template-columns: [start] repeat(var(--subgrid-width, auto-fill), [col] minmax(40%, 350px)) [end];
  }

  @media (--lg) {
    grid-template-columns: [start] repeat(var(--subgrid-width, auto-fill), [col] minmax(30%, 1fr)) [end];
  }
}

.navGroupSubcategory {
  grid-column: start/end;
  @media navShowDesktop {
    grid-column: unset;
    grid-column-start: span var(--column-span, 1);
  }

  @media (hover: hover) {
    &:has(.navGroupSubcategoryListItem:hover) {
      .navGroupSubcategoryItemLink {
        color: var(--deactivated-color, var(--color-alpha-light-20));

        &::before {
          color: var(--deactivated-color, var(--color-alpha-light-20));
        }
      }
    }
  }

  .navGroupSubcategoryList {
    display: grid;

    @media navShowDesktop {
      grid-auto-flow: column;
      grid-row-gap: unset;
      grid-template-columns: repeat(var(--column-span), auto);
      grid-template-rows: repeat(var(--max-rows, minRows), 0.5fr);
    }

    @media (--lg) {
      grid-template-columns: repeat(var(--column-span), 1fr);
    }
  }
}

.navGroupSubcategoryList {
  composes: listItemsByIndex from '~listItemsByIndex';
}

.navGroupSubcategoryListItem {
  composes: listItemsByIndexItem from '~listItemsByIndex';

  /* Need to make sure we override the above */
  /* stylelint-disable declaration-no-important */
  @media (hover: hover) {
    &:hover {
      .navGroupSubcategoryItemLink {
        color: var(--color-alpha-grey-100) !important;
        transition: color var(--duration-300ms) var(--ease-out) !important;

        &::before {
          color: var(--color-alpha-light-50) !important;
          transition: color var(--duration-300ms) var(--ease-out) !important;
        }
      }

      .navGroupSubcategoryItemInnerContent {
        transform: translateX(0.25rem) !important;
        transition: transform var(--duration-300ms) var(--ease-basic-butter) !important;
      }
    }
  }
  /* stylelint-enable declaration-no-important */
}

/* set the initial state, to be animated with gsap when subnav is opened */
.navGroupLabel,
.navGroupSubcategoryLabel,
.navGroupSubcategoryItemLink {
  opacity: 0;
  transform: translateX(100%);
  @media navShowDesktop {
    transform: unset;
  }
}

.navGroupSubcategoryItemLink {
  composes: listItemsByIndexItemLink from '~listItemsByIndex';
}

.navGroupSubcategoryItemInnerContent {
  composes: listItemsByIndexItemInner from '~listItemsByIndex';
}

.navGroupSubcategoryLabel {
  composes: eyebrow from '~typo';
  margin-bottom: var(--spacer-16);
  color: var(--color-turquoise-triumph);
  will-change: transform;
}

.navGroupCaret {
  position: relative;
  width: 0.5em;
  height: 1em;
  fill: var(--color-grey-60);
  transform: rotate(-90deg);

  @media navShowDesktop {
    width: 0.7em;
    height: 0.7em;
    margin-left: var(--spacer-8);
    fill: currentcolor;
    transform: rotate(0deg);
    transition: transform var(--duration-300ms) var(--ease-basic-butter);

    .open & {
      transform: rotate(180deg);
    }
  }
}
