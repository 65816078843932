.pageWrapper {
  position: relative;
  z-index: 1;
}

.footerImage {
  /* Since the footer image is now the "rings" asset, they need to be positioned so that the image doesn't scale with the browser, causing the rings to go behind the bookend text. This involves setting an absolute width to the image based on the size of the pages as they are designed in figma. margin is set based on what looks good visually. */
  --image-base-width: calc(768px * var(--default-image-aspect-ratio));
  position: absolute;
  z-index: -2;
  bottom: 0;
  left: 50%;
  width: var(--image-base-width);
  margin-bottom: calc(-1 * var(--image-base-width) / 4);
  transform: translate(-50%, 0);

  @media (--md) {
    --image-base-width: calc(960px * var(--default-image-aspect-ratio));
    margin-bottom: calc(-1 * var(--image-base-width) / 3.6);
  }

  @media (--lg) {
    --image-base-width: calc(1440px * var(--default-image-aspect-ratio));
    margin-bottom: calc(-1 * var(--image-base-width) / 3.2);
  }
}

.footerVideo {
  /* Following a similar approach as the image above to handle the responsive footer video */
  --video-base-width: calc(600px * var(--default-aspect-ratio));
  position: absolute;
  z-index: -2;
  bottom: 0;
  left: 50%;

  /* need to override the video wrapper width here */
  /* stylelint-disable-next-line declaration-no-important */
  width: var(--video-base-width) !important;
  height: auto;
  margin-bottom: calc(-1 * var(--video-base-width) / 3);
  transform: translate(-50%, 0);

  @media (--md) {
    --video-base-width: calc(500px * var(--default-aspect-ratio));
  }

  @media (--lg) {
    --video-base-width: calc(700px * var(--default-aspect-ratio));
  }

  @media (--xl) {
    --video-base-width: calc(1000px * var(--default-aspect-ratio));
  }
}

.footerGlow {
  position: absolute;
  z-index: var(--z-index-3);
  bottom: -75rem;
  left: -100%;
  width: 300%;
  height: 150rem;
  background: var(--footer-background-glow, transparent);
  mix-blend-mode: screen;
  pointer-events: none;

  @media (--lg) {
    bottom: -90rem;
    left: -15%;
    width: 130%;
  }
}

.error {
  composes: grid from '~g';
  position: relative;
  padding: 30vh 0;
  background-color: var(--color-grey-05);
  grid-column: start / end;
}

.errorTitle {
  composes: title3 from '~typo';
}

.errorMessage {
  composes: bodyMedium from '~typo';
}

.errorButtonsGroup {
  display: grid;
  justify-content: flex-start;
  gap: 2rem;

  @media (--md) {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
  }

  &:last-child {
    margin-bottom: 0;
  }
}
