.content {
  /* composes: content from './TextLockup.module.css'; */
  grid-column: start / end;

  --title-4-pothole-column-span: var(--grid-number-of-columns);

  @media (--md) {
    --title-4-pothole-column-span: var(--lockup-column-span, 5);
  }
}

.titles {
  composes: title4 from '~typo';
  grid-column: start / span var(--title-4-pothole-column-span);

  &:not(:first-child) {
    margin-top: var(--spacer-54);
  }

  @media (--md) {
    grid-column: var(--title-4-pothole-column-start, col 2) / span var(--title-4-pothole-column-span);
  }
}

.bodies {
  composes: bodyMedium from '~typo';
  margin-top: var(--spacer-16);
  color: var(--color-alpha-light-50);
}
