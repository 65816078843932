.desktop {
  @media (--sm-only) {
    display: none;
  }
}

.mobile {
  @media (--md) {
    display: none;
  }
}
