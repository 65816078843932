.accordionItem {
  padding-top: var(--spacer-32);
  padding-bottom: var(--spacer-32);
  border-bottom: 0.1rem solid var(--color-alpha-light-10);

  &:first-of-type {
    padding-top: initial;
  }

  &.isOpen {
    .accordionIcon {
      transform: rotate(180deg);
    }
  }
}

.header {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  cursor: pointer;
  text-align: left;
}

.accordionIcon {
  width: 1.6rem;
  height: 1.6rem;
  flex-shrink: 0;
  margin-left: var(--spacer-40);
  transition: transform var(--duration-500ms) var(--ease-basic-butter);

  path {
    fill: var(--color-alpha-light-60);
  }

  &.small {
    width: 1rem;
    height: 1rem;
  }
}

.content {
  overflow: hidden;
  padding-right: var(--spacer-40);
  transition: height var(--duration-500ms) var(--ease-basic-butter);

  @media (--md) {
    padding-right: var(--spacer-64);
  }

  & > * {
    &:first-child {
      padding-top: var(--spacer-8);
    }
  }
}
