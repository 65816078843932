.iconContainer {
  position: relative;
  display: flex;
  width: 2.57rem;
  height: 2.57rem;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;

  @media (--md) {
    width: 2.775rem;
    height: 2.775rem;
  }
}

.iconWrapper {
  display: flex;
  width: 1.5rem;
  height: 1.5rem;
  align-items: center;
  justify-content: center;

  .icon {
    width: 100%;
    height: 100%;
    object-fit: contain;

    path {
      fill: currentcolor;
    }
  }
}

.isLarge {
  .iconWrapper {
    width: 2rem;
    height: 2rem;
  }
}

.withBorder {
  border: 1px solid var(--button-icon-border-color, var(--color-alpha-light-5));
  border-radius: 50%;
  backdrop-filter: blur(7px);
  background: var(--button-icon-bg-color, var(--color-alpha-dark-10));

  .iconWrapper {
    display: flex;
    width: 0.92rem;
    height: 0.92rem;
    align-items: center;
    justify-content: center;
  }
}

.downloadIconContainer {
  .iconWrapper {
    width: 1.05rem;
    height: 1.05rem;
  }
}

.textLinkContainer {
  --icon-size: 3rem;

  .iconWrapper {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.arrow {
  width: var(--icon-size);
  height: var(--icon-size);

  --icon-color: var(--color-grey-97);
}

.playIconContainer {
  position: relative;
  display: flex;
  align-items: center;

  .iconWrapper {
    width: 2rem;
    height: 2rem;
  }
}

.playIcon {
  path {
    fill: currentcolor;
  }
}
