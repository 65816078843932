.content {
  composes: content from './TextLockup.module.css';

  --all-lockup-column-span: var(--grid-number-of-columns);
  @media (--md) {
    --all-lockup-column-span: var(--lockup-column-span, 10);
  }
}

.bodies,
.types,
.list,
.accents,
.titles {
  grid-column: start / end;
  text-align: var(--lockup-alignment);

  @media (--md) {
    grid-column: var(--lockup-column-start, start) / span var(--lockup-column-span, 10);
  }
}

/* Title 1s and 2s are left aligned on mobile by default */
.accents,
.title1,
.title2,
.title1 + .bodies,
.title2 + .bodies {
  text-align: left;

  @media (--md) {
    text-align: var(--lockup-alignment);
  }
}

.title1 ~ .bodies,
.title2 ~ .bodies,
.title3 ~ .bodies {
  /* Because:
  `composes: bodyMedium from '~typo';`
  is not allowed, we have to copy the code below
  */

  /* .bodyMedium */
  color: var(--color-alpha-light-50);
  font-family: var(--font-main, sans-serif);
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.025em;
  line-height: 1.35;

  @media (--md) {
    font-size: 1.7rem;
    letter-spacing: 0;
    line-height: 1.45;
  }

  @media (--lg) {
    font-size: 1.8rem;
    line-height: 1.45;
  }
}

.accents + .titles,
.types + .titles {
  margin-top: var(--spacer-12);
}

.bodies + .bodies {
  margin-top: var(--spacer-24);
}

.bodies + .titles,
.list + .titles {
  margin-top: var(--spacer-48);
}

.list {
  margin-top: var(--spacer-16);
  color: var(--color-bodies, var(--color-alpha-light-50));

  .list {
    margin-top: var(--spacer-8);
    list-style-type: circle;

    .list {
      list-style-type: square;

      .list {
        list-style-type: '-';
      }
    }
  }
}

.listItem {
  color: var(--color-bodies, var(--color-alpha-light-50));
}

.bodies {
  composes: bodies from './TextLockup.module.css';
  margin-top: var(--spacer-12);
  color: var(--color-bodies, var(--color-alpha-light-50));
}

/* types (sub-components) */

.types {
  composes: types from './TextLockup.module.css';
  display: flex;
  justify-content: var(--lockup-justify, flex-start);
  margin-top: var(--spacer-24);

  @media (--lg) {
    margin-top: var(--spacer-32);
  }
}

.types.buttonGroup {
  display: flex;
  justify-content: flex-start;
  margin-top: var(--spacer-24);
  gap: var(--spacer-16);

  @media (--md) {
    justify-content: var(--lockup-justify, flex-start);
  }

  @media (--lg) {
    margin-top: var(--spacer-24);
  }
}

.graphic {
  height: 3.6rem;

  > * {
    width: auto;
    height: 100%;
  }
}

.graphic + .titles {
  margin-top: var(--spacer-20);

  @media (--lg) {
    margin-top: var(--spacer-24);
  }
}

.forms {
  --placeholder-color: var(--color-grey-70);
}

:global(.textLockupCenter).content {
  @media (--md) {
    .bodies,
    .types,
    .list,
    .accents,
    .titles {
      grid-column: col calc((var(--grid-number-of-columns) / 2 - var(--all-lockup-column-span) / 2) + 1) / span var(--all-lockup-column-span);
    }
  }
}

.accents {
  composes: accents from './TextLockup.module.css';
}
