.content {
  composes: content from './TextLockup.module.css';
}

.titles,
.accents,
.bodies {
  padding: 0 var(--spacer-24);
  @media (--md) {
    padding: 0;
  }
}

.titles + .bodies {
  margin-top: var(--spacer-8);
  @media (--md) {
    margin-top: 0;
  }
}

/* default */

.titles,
.accents {
  grid-column: start/end;

  @media (--md) {
    --title-column-start: 2;
    --title-column-span: 4;
    grid-column: col var(--title-column-start) / span var(--title-column-span);
  }
  @media (--lg) {
    --title-column-start: 3;
    --title-column-span: 3;
  }
}

.bodies {
  grid-column: start/end;

  @media (--md) {
    --body-column-start: 7;
    --body-column-span: 5;
    grid-column: col var(--body-column-start) / span var(--body-column-span);
  }
  @media (--lg) {
    --body-column-start: 7;
    --body-column-span: 4;
  }
}

:global(.textLockupColumns10) {
  .titles,
  .accents {
    @media (--md) {
      --title-column-start: 1;
      --title-column-span: 5;
    }
    @media (--lg) {
      --title-column-start: 2;
      --title-column-span: 3;
    }
  }

  .bodies {
    @media (--md) {
      --body-column-start: 6;
      --body-column-span: 7;
    }
    @media (--lg) {
      --body-column-start: 6;
      --body-column-span: 6;
    }
  }
}

.accents {
  composes: accents from './TextLockup.module.css';
}
