.arrowWrapper {
  --default-icon-size: 3.5rem;
  --animation-duration: var(--duration-400ms);
  position: relative;
  display: flex;
  width: calc(var(--icon-size, var(--default-icon-size)) / 2.5);
  height: auto;
  transform: translate3d(calc(var(--arrow-offset) * var(--button-hover-progress, 0) * 1rem), 0, 0);
  transition: transform var(--animation-duration) var(--ease-micro-bounce);

  --tail-width: calc(var(--icon-size, var(--default-icon-size)) / 3);
  --arrow-offset: 0.85;

  &.previous {
    transform: translate3d(calc(-1 * var(--arrow-offset) * var(--button-hover-progress, 0) * 1rem), 0, 0) rotate(180deg);
  }
}

.cssTail {
  position: absolute;
  top: 50%;
  right: 0.02em;
  overflow: hidden;
  width: calc(var(--button-hover-progress, 0) * (var(--tail-width) * 2 - var(--tail-width)) + var(--tail-width));
  height: calc(var(--icon-size, var(--default-icon-size)) / 18);
  border-radius: 0.1rem;
  transform: translate3d(0, -50%, 0);
  transform-origin: 100% 50%;
  transition-duration: calc((var(--button-hover-progress)) / 1 * var(--animation-duration));
  transition-property: width;
  transition-timing-function: var(--ease-micro-bounce);

  &::after {
    position: absolute;
    right: 0;
    width: calc(var(--tail-width) * 2);
    height: 100%;
    border-radius: 0.1rem;
    background-color: var(--icon-color, var(--color-grey-97));
    content: '';
    transform: scaleX(calc((var(--button-hover-progress, 0)) * (0.5 - 1) + 1));
    transform-origin: 100% 50%;
    transition: transform var(--animation-duration) var(--ease-micro-bounce);
    transition-delay: calc(var(--button-hover-progress, 0) * (var(--animation-duration) / 3));
  }
}

.arrowSvg {
  position: absolute;
  top: 50%;
  right: 0;
  height: calc(var(--icon-size, var(--default-icon-size)) / 4);
  transform: translateY(-50%);

  .arrowHead {
    fill: var(--icon-color, var(--color-grey-97));
  }
}
